import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const RefundPolicy = () => {
  return (
    <Layout>
      <section className="bg-heading">
        <h4>
          <b>Refund Policy</b>
        </h4>
      </section>
      <section className="terms-container">
        <p>
          Our refund policy lasts 30 days. If 30 days have passed since your
          purchase, we can't offer you a full refund or exchange.
        </p>
        <p>
          To be eligible for refund, we require a receipt or proof of purchase.
        </p>
        <p>
          Once your refund request is received and inspected, we will send you
          an email to notify you that we have received your request for refund.
          We will also notify you of the approval or rejection of your refund.
        </p>
        pIf you are approved, then your refund will be processed, and a credit
        will automatically be applied to your credit card or original method of
        payment, within a certain amount of days.
        <h5 className="mt-5">Late or missing refunds</h5>
        <p>
          If you haven't received a refund yet, first check your bank account
          again.
        </p>
        <p>
          Next contact your bank. There is often some processing time before a
          refund is posted.
        </p>
        <p>
          If you've done all of this and you still have not received your refund
          yet, please contact us at zolandstore23@gmail.com
        </p>
        <h5 className="mt-5">Need help?</h5>
        <p>
          Contact us at zolandstore23@gmail.com or +91 7085414571 for questions
          related to refunds policy.
        </p>
      </section>
    </Layout>
  );
};

export default RefundPolicy;
