import React, { useRef } from "react";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IMAGES from "../../img/image";
import "./HeroSection.css";

const HeroSection = () => {
  const arrowRef = useRef();

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 100,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // async function getBanners() {
  //   try {
  //     const res = await axios.get("/api/banner/get-banners", {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //     if (res.data.success) {
  //       setBanners(res.data.data);
  //     } else {
  //       message.error(res.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   getBanners();
  // }, []);

  return (
    <>
      {/* Desktop and Tablet  */}
      <div className="container-fluid hero-container">
        <Slider ref={arrowRef} {...settings}>
          <div className="p-3">
            <img src={IMAGES.banner1} className="d-block w-100" alt="banners" />
          </div>
          <div className="p-3">
            <img src={IMAGES.banner2} className="d-block w-100" alt="banners" />
          </div>
          <div className="p-3">
            <img src={IMAGES.banner3} className="d-block w-100" alt="banners" />
          </div>
          <div className="p-3">
            <img src={IMAGES.banner4} className="d-block w-100" alt="banners" />
          </div>
          <div className="p-3">
            <img src={IMAGES.banner5} className="d-block w-100" alt="banners" />
          </div>
        </Slider>
        <div className="hero-slider-btns d-none">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon arrow-left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon arrow-right" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
