import React from "react";
import Layout from "../components/Layout/Layout";
import "./About.css";

const About = () => {
  return (
    <Layout>
      <div className="bg-heading">
        <h4>
          <b>About Us</b>
        </h4>
      </div>
      <div className="terms-container">
        <p>You may contact us using the information below:</p>
        <ul>
          <li>Merchant Legal entity name: Ht Liana Hrangate</li>
          <li>
            Registered Address: Y 80/B Mission Vengthlang Aizawl MIZORAM 796001
          </li>
          <li>
            Operational Address: Y 80/B Mission Vengthlang Aizawl MIZORAM 796001
          </li>
          <li>Telephone No: +91 7085414571</li>
          <li>E-Mail ID: zolandstore23@gmail.com</li>
        </ul>
      </div>
    </Layout>
  );
};

export default About;
